import { environment as rivalEnvironment } from './environment.rival';

export const environment = {
  ...rivalEnvironment,
  isAffiliated: true,

  rivalName: "candyland",
  casinoId: 325,
  GA_TRACKING: "G-J2GM1TJSQT",

  rivalChatGroupName: "CandyLand",
  prettyName: "Candyland",
  contactEmail: "support@candyland.casino",
  docsEmail: "documents@candyland.email",

  publicPromotions: [
    {id: 'PARADISE8_1', name: "deposit", state: "eligible", details: "welcome", imgPath: "assets/brands/candyland/promo.png"},
    {id: 2, name: "cashbackInsurance", state: "eligible", details: "welcome", imgPath: "assets/brands/candyland/promo.png"},
    {id: 'PARADISE8_3', name: "nextDayCashback", state: "eligible", details: "weekly", imgPath: "assets/brands/candyland/promo.png"},
    {id: 'PARADISE8_4', name: "loyalty", state: "eligible", details: "weekly", imgPath: "assets/brands/candyland/promo.png"}
  ],

  legalData: {
    withdrawalTime: 14,
    affTerms: true
  },

  //SEO
  metaDescription: "Join Candyland Online Casino without depositing, earn no deposit bonus, free spins and exclusive cash promotions. Play slot games with fair chances, get fast payouts and deposit with crypto.",
  logoName: "candyland-online-casino-logo.png",
  logoAltText: "Candyland Casino official logo, featuring vibrant pink and blue text with a candy swirl design, representing a fun and playful online casino offering a wide selection of slots, table games, and exciting promotions. Enjoy a sweet and secure online gambling experience at Candyland Casino."

};


